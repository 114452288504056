<template>
    <section id="Members">
        <BookSelector :books="managedBooks" :selectedName="profile.books[$route.params.book_id].name" />
        <div v-if="filter === 'joined'" :class="{ closed: (Object.keys(book).length > 0 && !book.open) || false }">
            <div v-if="bookie">
                <h3>Bookie</h3>
                <Member :userProfile="bookie" :book="profile.books[$route.params.book_id]" :wagers="wagers" :bets="bets" profit />
            </div>
            <div v-if="admins.length > 0">
                <h3>Admins</h3>
                <Member
                    v-for="admin in admins"
                    :key="admin.username"
                    :id="admin.id"
                    :userProfile="admin"
                    lock="left"
                    hasDemote
                    @demote="demote"
                    :book="profile.books[$route.params.book_id]"
                    :wagers="wagers"
                    :bets="bets"
                    profit
                />
            </div>
            <div v-if="joined.length > 0">
                <h3>Members</h3>
                <Member
                    v-for="member in joined"
                    :key="member.username"
                    :id="member.id"
                    :userProfile="member"
                    lock="none"
                    hasPromote
                    hasDelete
                    @promote="promote"
                    @delete="memberToRemove = member"
                    :book="profile.books[$route.params.book_id]"
                    :wagers="wagers"
                    :bets="bets"
                    profit
                />
                <Confirm v-if="memberToRemove" okay="Yes" @okay="remove(memberToRemove.id)" @cancel="cancel">Are you sure you want to remove {{ memberToRemove.firstName }} {{ memberToRemove.lastName }} ({{ memberToRemove.username }}) from {{ book.name }}?</Confirm>
            </div>
        </div>
        <div v-if="filter === 'pending'">
            <div v-if="pending.length > 0">
                <h3>Pending</h3>
                <Member v-for="member in pending" :key="member.username" :userProfile="member" />
            </div>
            <h3 class="no-results" v-else>No results available.</h3>
        </div>
        <div v-if="filter === 'declined'">
            <div v-if="declined.length > 0">
                <h3>Declined</h3>
                <Member v-for="member in declined" :key="member.username" :userProfile="member" />
            </div>
            <h3 class="no-results" v-else>No results available.</h3>
        </div>
        <div v-if="filter === 'removed'">
            <div v-if="removed.length > 0">
                <h3>Removed</h3>
                <Member v-for="member in removed" :key="member.username" :userProfile="member" />
            </div>
            <h3 class="no-results" v-else>No results available.</h3>
        </div>
        <div class="close-book">
            <Button theme="red" @onClick="closeBook" v-if="(Object.keys(book).length > 0 && book.open)">Close Book</Button>
            <Button arrow @onClick="reopenBook" v-if="(Object.keys(book).length > 0 && !book.open)">Re-Open Book</Button>
        </div>
        <router-link :to="{ name: 'InviteMembers', params: { book_id: $route.params.book_id } }" :class="{ closed: (Object.keys(book).length > 0 && !book.open) || false }">
            <Floater icon="invite" />
        </router-link>
        <Filters :filters="filters" :selected="filter" @select="changeFilter" :class="{ closed: (Object.keys(book).length > 0 && !book.open) || false }" />
        <Options :options="options" :selected="0" hideShadow />
    </section>
</template>

<script>

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Options from '@/components/Options.vue';
    import Member from '@/components/Member.vue';
    import Button from '@/components/Button.vue';
    import Filters from '@/components/Filters.vue';
    import Floater from '@/components/Floater.vue';
    import Confirm from '@/components/Confirm.vue';

    // Firebase.
    import { watchBook, promote, demote, remove, closeBook, reopenBook } from '@/firebase/books.js';
    import { getWager, getBet } from '@/firebase/wagers.js';
    import { getProfile } from '@/firebase/auth.js';

    // Misc.
    import { MANAGE_OPTIONS } from '@/constants.js';

    export default {
        name: 'Members',
        components: {
            BookSelector,
            Options,
            Member,
            Button,
            Filters,
            Floater,
            Confirm,
        },
        data() {
            return {
                book: {},
                wagers: {},
                bets: {},
                bookie: null,
                members: [],
                filter: 'joined',
                memberToRemove: null,
            }
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            admins() {
                return this.filterGroup('admin');
            },
            joined() {
                return this.filterGroup('member');
            },
            pending() {
                return this.filterGroup('pending');
            },
            declined() {
                return this.filterGroup('declined');
            },
            removed() {
                return this.filterGroup('removed');
            },
            options() {
                return MANAGE_OPTIONS.map(option => {
                    return { ...option, params: { book_id: this.$route.params.book_id } };
                });
            },
            filters() {
                return ['joined', 'pending', 'declined', 'removed'];
            },
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
        },
        created() {
            this.$store.dispatch('updateLoading', 1);
            watchBook(this.$route.params.book_id).on('value', this.listen);
        },
        watch: {
            $route(to, from) {
                this.$store.dispatch('updateLoading', 1);
                watchBook(from.params.book_id).off('value', this.listen);
                watchBook(to.params.book_id).on('value', this.listen);
            }
        },
        beforeDestroy() {
            watchBook(this.$route.params.book_id).off('value', this.listen);
        },
        methods: {
            async listen(snapshot) {

                const val = snapshot.val();

                this.book    = val;
                this.bookie  = (await getProfile(val.bookie)).val();
                this.members = [];
                this.wagers = {};
                this.bets = {};

                if (val.members) {
                    for (let member of Object.entries(val.members)) {

                        this.$store.dispatch('updateLoading', 1);

                        getProfile(member[0]).then(snapshot => {
                            this.members.push({ ...snapshot.val(), id: member[0], status: member[1] });
                            this.$store.dispatch('updateLoading', -1);
                        });

                    }
                }

                if (val.wagers) {
                    for (let wager of Object.keys(val.wagers)) {

                        this.$store.dispatch('updateLoading', 1);

                        getWager(wager).then(snapshot => {
                            this.wagers = { ...this.wagers, [snapshot.key]: snapshot.val() };
                            this.$store.dispatch('updateLoading', -1);
                        });

                    }
                }

                if (val.bets) {
                    for (let bet of Object.keys(val.bets)) {

                        this.$store.dispatch('updateLoading', 1);

                        getBet(bet).then(snapshot => {
                            this.bets = { ...this.bets, [snapshot.key]: snapshot.val() };
                            this.$store.dispatch('updateLoading', -1);
                        });

                    }
                }

                this.$store.dispatch('updateLoading', -1);

            },
            closeBook() {

                let members = [this.book.bookie];
                if (this.book.members) members = members.concat(Object.keys(this.book.members));

                this.$store.dispatch('updateLoading', 1);

                closeBook(this.$route.params.book_id, members).then(() => this.$store.dispatch('updateLoading', -1));

            },
            reopenBook() {

                let members = [this.book.bookie];
                if (this.book.members) members = members.concat(Object.keys(this.book.members));

                this.$store.dispatch('updateLoading', 1);

                reopenBook(this.$route.params.book_id, members).then(() => this.$store.dispatch('updateLoading', -1));

            },
            changeFilter(filter) {
                this.filter = filter;
            },
            filterGroup(group) {
                return this.members.filter(member => member.status === group).sort((a, b) => {
                    return `${ b.firstName } ${ b.lastName }` < `${ a.firstName } ${ a.lastName }` ? 1 : -1;
                });
            },
            promote(userId) {
                promote(userId, this.$route.params.book_id);
            },
            demote(userId) {
                demote(userId, this.$route.params.book_id);
            },
            remove(userId) {
                this.cancel();
                remove(this.user.uid, userId, this.$route.params.book_id);
            },
            cancel() {
                this.memberToRemove = null;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Members {
        overflow: hidden;
        padding: size(Large) size(Medium) (50px + size(Large) + size(Medium) + size(Large) + size(Medium));
    }

    .Member {
        margin-top: size(Small);
    }

    h3 {
        margin-top: size(Medium);
    }

    .no-results {
        text-align: center;
    }

    .close-book {
        margin-top: size(Medium);
        padding: 0 size(Large);
    }

    .Filters {
        position: fixed !important;
        bottom: 50px;
        left: 0;
        z-index: 10;
    }

    .Floater {
        position: fixed !important;
        bottom: size(Medium) + size(Large) + 50px;
        right: size(Medium);
        z-index: 5;
    }

    .closed {
        opacity: 0.15;
        pointer-events: none;
    }

</style>
